import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class OnlineFacilityController extends Controller {
  connect() {
    this.id = this.element.dataset.id;
  }

  publish(e) {
    e.preventDefault();

    const link = e.target;
    const url = link.getAttribute('href');

    axios.put(url).then((response) => {
      this.update();
    }).catch((error) => {
      Helper.flash_message('error', e.response.data.errors.join(', '));
    })
  }

  update() {
    const url = `/admin/membership_information/${this.id}`;

    axios.get(url, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } })
      .then((r) => {
        Turbo.renderStreamMessage(r.data);
      })
      .catch((error) => Helper.flash_message('error', 'Something went wrong'));
  }
}

export default OnlineFacilityController;
